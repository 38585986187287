import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { injectIntl } from "gatsby-plugin-intl"
import NavBar from "../components/navbar"
import SEO from "../components/SEO/index"
import Slick from "../components/Slick"
import { Section } from "../components/CompoundComponents/Section"
import { Columns, Column } from "../components/CompoundComponents/Columns"
import { Box } from "../components/CompoundComponents/Box"
import Footer from "../components/Footer/index"

const TurkishSaunaPage = ({ data, intl }) => {
  return (
    <>
      <SEO
        title="Ниҳол саломатлик сиҳатгоҳи, Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган"
        description="Один из лучших санаториев Узбекистана - Санаторий Нихол, Санаторий Учкоргон, Санаторий Узбекистан, Санаторий Наманган, Санаторий Андижан, Санаторий Фергана, Санаторий Ташкент, Санаторий Киргизия, Отдых санаторий Узбекистан, Санаторий Учкурган, Ниҳол саломатлик сиҳатгоҳи"
      />
      <NavBar />
      <Section>
        <Columns>
          <Column is8>
            <Slick slideToShow={1} slideToScroll={1} speed={3000}>
              {data.turkish.edges.map(item => (
                <Img
                  fluid={item.node.featuredImage.childImageSharp.fluid}
                  alt={item.node.id}
                />
              ))}
            </Slick>
            <Box white>
              <div className="content">
                <h3 className="is-size-6-mobile">
                  {" "}
                  {intl.formatMessage({
                    id: `turkish-sauna.title`,
                  })}
                </h3>
                <p>
                  {" "}
                  {intl.formatMessage({
                    id: `turkish-sauna.desc`,
                  })}
                </p>
              </div>
            </Box>
          </Column>
          <Column is4>
            <article className="message is-warning is-shadow">
              <div className="message-body">
                <div className="content">
                  <h3>
                    {intl.formatMessage({
                      id: `turkish-sauna.title`,
                    })}{" "}
                    (
                    {intl.formatMessage({
                      id: `paid.title`,
                    })}
                    )
                  </h3>
                  <p>
                    {intl.formatMessage({
                      id: `free.title`,
                    })}
                  </p>
                </div>
              </div>
            </article>
            <article className="message is-danger is-shadow">
              <div className="message-body">
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.title`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-1`,
                  })}
                </p>
                <br />
                <p>
                  {intl.formatMessage({
                    id: `treatment.attention.desc-2`,
                  })}
                </p>
              </div>
            </article>
          </Column>
        </Columns>
      </Section>
      <Footer data={data.section.edges[0]} />
    </>
  )
}

export const TurkishSaunaPageQuery = graphql`
  query {
    turkish: allTurkishsaunaYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    section: allProductsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(TurkishSaunaPage)
